<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

import { required } from '@/libs/validations'

export default {
  name: 'AddLocationStep',
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
  },
  props: ['data'],

  methods: {
    handleAddressChange(address) {
      let metadata = {}
      this.data.city = 'any'
      for (const component of address.address_components) {
        if (component.types.includes('locality')) {
          this.data.city = component.long_name
        }
        if (component.types.includes('sublocality')) {
          metadata.neighborhood = component.long_name
        }
        if (component.types.includes('postal_code')) {
          metadata.postal_code = component.long_name
        }
      }
      this.data.google_place_id = address.place_id
      this.data.details = address.formatted_address
      this.data.latitude = address.geometry.location.lat()
      this.data.longitude = address.geometry.location.lng()
      this.data.metadata = JSON.stringify(metadata)
    },
    handleInput(e) {
      if (!e.data) {
        this.data.details = ''
        this.data.city = ''
        this.data.google_place_id = ''
        this.data.metadata = ''
        this.data.latitude = 41.3873974
        this.data.longitude = 2.168568
      }
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <div class="info">
      <h2 class="info-title">{{ $t('Put yourself on the map!') }}</h2>
      <p class="info-content">{{ $t('add_location_help_1') }}</p>
      <div class="screenshot-wrapper">
        <img
          src="@/assets/images/screenshots/search-result.webp"
          alt="Screenshot of search results in the map"
          class="screenshot"
        />
      </div>
      <p class="info-content">{{ $t('add_location_help_2') }}</p>
    </div>
    <div class="form">
      <b-form-group :label="$t('Location name')" label-for="name">
        <validation-provider
          #default="{ errors, valid }"
          :name="$t('Location name')"
          rules="required"
        >
          <b-form-input
            id="name"
            v-model="data.name"
            :state="valid ? null : false"
            :placeholder="$t('Location name')"
          />
          <small v-for="error in errors" class="text-danger">{{ error }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group :label="$t('Address')" label-for="details">
        <gmap-autocomplete
          ref="locationautocomplete"
          :value="data.details"
          class="form-control"
          :placeholder="$t('Search your address')"
          @place_changed="handleAddressChange"
          @input="handleInput"
        />
      </b-form-group>
      <GmapMap
        :center="{ lat: data.latitude, lng: data.longitude }"
        :zoom="15"
        style="width: 100%; height: 400px"
        :options="{
          fullscreenControl: false,
          zoomControl: true,
          rotateControl: false,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
        }"
      >
        <GmapMarker :position="{ lat: data.latitude, lng: data.longitude }" :clickable="false" />
      </GmapMap>
    </div>
  </div>
</template>

<style>
/* Global override to make sure the dropdown from the google maps autocomplete is on top of the modal  */
.pac-container {
  z-index: 2000;
}
</style>

<style scoped>
.wrapper {
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.info-title {
  margin: 0;
}

.info-content {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.screenshot-wrapper {
  max-width: 100%;
  align-self: center;
}

.screenshot {
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
}

@media screen and (min-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .screenshot-wrapper {
    max-width: 75%;
  }
}
</style>
