<script>
import { BButton, BModal, BFormTextarea, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import HelpService from '@/services/HelpService'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormGroup,
    BModal,
    BFormTextarea,
    BFormInput,
  },
  data() {
    return {
      api: new HelpService(),
      subject: '',
    }
  },
  methods: {
    async sendHelp() {
      try {
        await this.api.create({
          subject: this.subject,
          raised_by: this.$store.state.auth.user.email,
        })

        this.$refs['modal-help'].hide()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<template>
  <div>
    <b-modal id="modal-help" ref="modal-help" :title="$t('Need help?')" hide-footer>
      <p class="my-1">
        {{ $t('help_intro') }}
      </p>
      <b-form-group :label="$t('Problem description')" label-for="subject">
        <b-form-textarea id="subject" v-model="subject" rows="7" />
      </b-form-group>
      <b-form-group :label="$t('Email')" label-for="email">
        <b-form-input id="email" :value="$store.state.auth.user.email" disabled />
      </b-form-group>
      <b-form-group>
        <b-button class="float-right" variant="primary" size="lg" @click="sendHelp">
          {{ $t('Send') }}
        </b-button>
      </b-form-group>
    </b-modal>
    <div class="send-incidencia">
      <!-- We have wrapper because ripple effect give position relative to this absolute positioned btn -->
      <!-- Hence due to that our btn get lost -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.modal-help
        variant="secondary"
        class="btn-icon"
      >
        <feather-icon icon="HelpCircleIcon" size="16" class="mr-1" />
        <span>{{ $t('Need help?') }}</span>
      </b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.send-incidencia {
  position: fixed;
  bottom: 1em;
  right: 2em;
}
</style>
