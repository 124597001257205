import CrudService from "@/services/shared/http/CrudService"

export default class ActivityService extends CrudService {
    constructor(params = {}) {
        super('activities', params)
    }

    changeStatus(id) {
        return this.api.PUT(this.endPoint + '/publish/' + id)
    }

    duplicateActivity(params) {
        return this.api.POST(this.endPoint + '/duplicate', params)
    }

    getCategoriesList() {
        return this.api.GET('categories', {per_page: 200})
    }

    getLocationsList() {
        return this.api.GET('locations', {per_page: 200})
    }
    getWeekDayList() {
        return this.api.GET('days')
    }

    getProvidersList() {
        return this.api.GET('providers', {per_page: 200})
    }

    getLanguagesList() {
        return this.api.GET('languages', {per_page: 200})
    }

}
