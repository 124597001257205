<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import config from '@/core/config/httpConfig'
import { BProgress } from 'bootstrap-vue'
import ToastificationContent from '@/components/ToastificationContent'
import Index from '@/libs/i18n/index'

export default {
  name: 'DropZone',
  components: {
    vueDropzone: vue2Dropzone,
    BProgress,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    filesThumbnail: {
      type: Array,
      default: () => [],
    },
    customStyle: {
      default: '',
      type: String,
    },
    filesIDs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      dropzoneOptions: {
        timeout: 1000000,
        url: config.apiUrl + 'upload-media',
        paramName: 'files[]',
        maxFiles: 20,
        acceptedFiles: 'image/png,image/jpeg',
        maxFilesize: 15_000_000, // MB
      },
    }
  },
  methods: {
    vfileAdded(file) {
      const reader = new FileReader()
      const myFile = {}
      setTimeout(() => {
        reader.addEventListener(
          'load',
          function () {
            // convert image file to base64 string
            myFile.original_url = reader.result
            myFile.progress = 1
            myFile.id = null
            myFile.error =
              file.status === 'error'
                ? `${Index.t('large file!')}<br>${Index.t('Max size 15MB')}`
                : null
          },
          false
        )
        reader.readAsDataURL(file)
        setTimeout(() => {
          this.filesThumbnail.push(myFile)
        }, 50)
      }, 50)
    },
    reflectProgress(file, progress, bytesSent) {
      this.filesThumbnail.map(f => {
        if (f.original_url === file.dataURL) {
          f.progress = progress
        }
      })
    },
    vsuccess(file, response) {
      this.filesThumbnail.map(f => {
        if (f.original_url === file.dataURL) {
          if (response.data && response.data[0].media.length) {
            this.filesIDs.push(response.data[0].media[0].id)
            this.$emit('data-changed', this.filesIDs)
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: this.$t('Error uploading image'),
                  icon: 'AlertTriangleIcon',
                  text: this.$t(response.errors[0]),
                  variant: 'danger',
                },
              },
              {
                timeout: 15_000,
              }
            )
          }
        }
      })
    },
    vdropzoneError(file, message, xhr) {
      console.log(file)
      console.error(message)
      this.filesThumbnail.map(f => {
        if (f.original_url === file.dataURL) {
          f.error = Index.t('Failed to upload.')
        }
      })
      console.log(xhr)
    },
    onRemove(file) {
      this.filesThumbnail.splice(this.filesThumbnail.indexOf(file), 1)
      this.filesIDs.splice(this.filesIDs.indexOf(file.id), 1)
      this.$emit('data-changed', this.filesIDs)
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <label>{{ $t(label) }}</label>
      <small class="hint">{{ $t('photos should be in PNG or JPG') }}</small>
    </div>
    <div class="col-12" />
    <div class="col-sm-4 col-md-4 col-xl-3 mb-2">
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        :use-custom-slot="true"
        class="my-dropzone"
        :style="customStyle"
        :options="dropzoneOptions"
        @vdropzone-upload-progress="reflectProgress"
        @vdropzone-success="vsuccess"
        @vdropzone-error="vdropzoneError"
        @vdropzone-file-added="vfileAdded"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">+</h3>
        </div>
      </vue-dropzone>
    </div>
    <div v-for="(f, i) in filesThumbnail" :key="i" class="col-sm-4 col-md-4 col-xl-3 mb-2">
      <div class="images-slider">
        <img class="file-thumbnail" :style="customStyle" :src="f.original_url" alt="" />
        <feather-icon icon="XIcon" size="15" @click="onRemove(f)" />
        <div v-if="f.progress > 1 && f.progress <= 100 && !f.id" class="progress-style">
          <BProgress :value="f.progress" max="100" />
        </div>
        <div v-if="f.error" class="bg-danger progress-style" v-html="f.error" />
        <div v-if="f.progress === 100 && f.id" class="bg-success progress-style">Done</div>
      </div>
    </div>
  </div>
</template>

<style>
.dropzone .dz-message {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background: transparent;
}

.dropzone .dz-message .dropzone-custom-title {
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  right: 0;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview {
  position: absolute !important;
  z-index: -10;
}

.images-slider {
  margin-bottom: 5px;
  /*margin-right: 1rem;*/
  position: relative;
  border-radius: 6px;
}

.images-slider svg {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 99;
  background: #eee;
  padding: 0.3rem;
  border-radius: 20px;
  font-size: 10px;
  display: none;
  cursor: pointer;
}

.images-slider:hover svg {
  display: block;
}

.file-thumbnail {
  position: relative;
  height: 100px;
  opacity: 0.8;
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.file-thumbnail:hover {
  opacity: 0.7;
}

.progress-style {
  bottom: 0;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.my-dropzone {
  display: flex;
  align-items: center;
  position: relative;
  min-height: unset;
  height: 100px;
  background: transparent;
  cursor: pointer;
  border-color: #c1c5d8;
  color: #717386;
  border-radius: 5px;
  font-size: 16px;
  overflow: hidden;
}

.my-dropzone img {
  width: 5rem;
  display: flex;
}
.hint {
  font-size: 11px;
  color: var(--gray);
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: -0.2rem;
}
</style>

<style scoped>
.bg-success {
  background: var(--primary) !important;
}
@media (max-width: 1281px) and (min-width: 1200px) {
  .my-dropzone,
  .file-thumbnail {
    height: 90px;
  }
}

@media (max-width: 900px) {
  .my-dropzone,
  .file-thumbnail {
    height: 80px;
  }
}
</style>
