var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"info"},[_c('h2',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.$t('Set up your profile!')))]),_c('p',{staticClass:"info-content"},[_vm._v(_vm._s(_vm.$t('complete_profile_help_1')))]),_vm._m(0),_c('p',{staticClass:"info-content"},[_vm._v(_vm._s(_vm.$t('complete_profile_help_2')))])]),_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"logo-field"},[_c('div',{staticClass:"profile-image",on:{"click":function($event){return _vm.$refs.myFile.click()}}},[_c('input',{ref:"myFile",staticClass:"hidden",attrs:{"type":"file"},on:{"change":_vm.handleLogoChange}}),(_vm.logoPreview)?_c('img',{staticClass:"profile-image",attrs:{"src":_vm.logoPreview,"alt":"Upload your business logo"}}):_c('div',{staticClass:"profile-image profile-image-empty"},[_vm._v("+")])]),_c('p',{staticClass:"logo-field-helper",on:{"click":function($event){return _vm.$refs.myFile.click()}}},[_vm._v(" "+_vm._s(_vm.logoPreview ? _vm.$t('Edit logo') : _vm.$t('Please add your business logo'))+" ")])]),_c('div',{staticClass:"basic-info"},[_c('p',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('Basic info')))]),_c('b-form-group',{attrs:{"label":_vm.$t('Business name'),"label-for":"business_name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"business_name","name":"business_name","state":valid ? null : false},model:{value:(_vm.data.business_name),callback:function ($$v) {_vm.$set(_vm.data, "business_name", $$v)},expression:"data.business_name"}}),_vm._l((errors),function(error){return _c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Business representative'),"label-for":"representative"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business representative'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"representative","name":"representative","state":valid ? null : false},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_vm._l((errors),function(error){return _c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('b-form-group',{staticClass:"description-field",attrs:{"label-for":"about","label":_vm.$t('Description')}},[_c('b-form-textarea',{attrs:{"id":"about","placeholder":_vm.$t('Description'),"rows":"8"},model:{value:(_vm.data.about),callback:function ($$v) {_vm.$set(_vm.data, "about", $$v)},expression:"data.about"}})],1),_c('DropZone',{staticClass:"images-field",attrs:{"label":_vm.$t('Add photos (Min 1 photo Max 8 photos)'),"files-thumbnail":_vm.data.mediaPreviews,"files-i-ds":_vm.data.files},on:{"data-changed":_vm.handleImagesChange}})],1),_c('div',{staticClass:"contact-info"},[_c('p',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('Contact info')))]),_c('b-form-group',{staticClass:"position-relative",attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","readonly":"","name":"email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"phone","name":"phone","state":valid ? null : false},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}}),_vm._l((errors),function(error){return _c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Website URL'),"label-for":"website"}},[_c('b-form-input',{attrs:{"id":"website","placeholder":"https://www.toddl.co/example"},model:{value:(_vm.data.website_url),callback:function ($$v) {_vm.$set(_vm.data, "website_url", $$v)},expression:"data.website_url"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Facebook link'),"label-for":"facebook"}},[_c('b-form-input',{attrs:{"id":"facebook","placeholder":"https://www.facebook.co/example"},model:{value:(_vm.data.facebook_url),callback:function ($$v) {_vm.$set(_vm.data, "facebook_url", $$v)},expression:"data.facebook_url"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Instagram link'),"label-for":"instagram"}},[_c('b-form-input',{attrs:{"id":"instagram","placeholder":"https://www.instagram.co/example"},model:{value:(_vm.data.instagram_url),callback:function ($$v) {_vm.$set(_vm.data, "instagram_url", $$v)},expression:"data.instagram_url"}})],1)],1),_c('div',{staticClass:"activities-info"},[_c('p',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('Activities info')))]),_c('b-form-group',{attrs:{"label-for":"category","label":_vm.$t('Categories')}},[_c('vue-select',{attrs:{"id":"category","multiple":"","placeholder":_vm.$t('Categories'),"label":"name","options":_vm.categories,"reduce":function (option) { return option.id; }},model:{value:(_vm.data.categories),callback:function ($$v) {_vm.$set(_vm.data, "categories", $$v)},expression:"data.categories"}})],1),_c('b-form-group',{attrs:{"label-for":"age","label":_vm.$t('Age Range')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Age Range'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('vue-slider',{staticClass:"profile-age-range",attrs:{"id":"age","min":0,"max":19,"marks":_vm.ageRangeSteps,"included":true,"hide-label":true,"process-style":{ backgroundColor: '#6BC4C5' },"tooltip-style":{
                backgroundColor: '#F8F8F8',
                borderColor: '#F8F8F8',
                color: '#0E1A1A',
                fontWeight: '700',
              },"tooltip":'always',"state":valid ? null : false},scopedSlots:_vm._u([{key:"tooltip",fn:function(ref){
              var value = ref.value;
return [_c('div',{staticClass:"tooltip-slider"},[_vm._v(_vm._s(_vm.getAgeRangeTooltipText(value)))])]}}],null,true),model:{value:(_vm.data.ageRange),callback:function ($$v) {_vm.$set(_vm.data, "ageRange", $$v)},expression:"data.ageRange"}}),_vm._l((errors),function(error){return _c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screenshot-wrapper"},[_c('img',{staticClass:"screenshot",attrs:{"src":require("@/assets/images/screenshots/provider-details.webp"),"alt":"Screenshot of provider details page"}})])}]

export { render, staticRenderFns }