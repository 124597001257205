<template>
  <div v-if="$store.state.auth.user">
    <layout-vertical>
      <template v-slot:navbar="{ toggleVerticalMenuActive }">
        <div class="navbar-container d-flex content align-items-center">
          <!-- Nav Menu Toggler -->
          <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
              <b-link class="nav-link" @click="toggleVerticalMenuActive">
                <feather-icon icon="MenuIcon" size="21" />
              </b-link>
            </li>
          </ul>
          <FreeTrialReminder v-show="daysLeft > 0" :daysLeft="daysLeft" />

          <!-- Left Col -->
          <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <b-navbar-nav class="nav align-items-center mr-auto" />
          </div>

          <b-navbar-nav class="nav align-items-center ml-auto">
            <UserDropdown />
            <locale />
          </b-navbar-nav>
        </div>
      </template>
      <router-view />
    </layout-vertical>
    <Onboarding :showOnboarding="showOnboarding" />
    <HelpWidget />
  </div>
</template>

<script>
import { BNavbarNav } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'

import HelpWidget from '@/components/HelpWidget'
import Locale from '@/components/Locale'
import Onboarding from '@/components/Onboarding/Onboarding'
import UserDropdown from '@/components/UserDropdown'
import FreeTrialReminder from '@/components/FreeTrialReminder'

import LayoutVertical from './vertical/LayoutVertical.vue'

export default {
  components: {
    LayoutVertical,
    BNavbarNav,
    Locale,
    UserDropdown,
    BLink,
    Onboarding,
    HelpWidget,
    FreeTrialReminder,
  },
  computed: {
    showOnboarding() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.plan === 'restricted' &&
        !this.$store.state.auth.user.trial_ends_at
      )
    },
    daysLeft() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.trial_ends_at) {
        const diffInMs = new Date(this.$store.state.auth.user.trial_ends_at) - new Date()

        return Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
      }
    },
  },
  mounted() {
    if (!this.$store.state.auth.user) {
      this.$store.dispatch('auth/fetchUserData').then(() => {
        const isImpersonatingUser = localStorage.getItem('isImpersonatingUser')

        if (isImpersonatingUser) {
          this.$posthog.identify('IMPERSONATED_MASTER_USER')
        } else {
          this.$posthog.identify(this.$store.state.auth.user.id.toString(), {
            email: this.$store.state.auth.user.email,
            name: this.$store.state.auth.user.name,
            provider: this.$store.state.auth.user.business_name,
            plan: this.$store.state.auth.user.plan,
            period: this.$store.state.auth.user.payment_frequency,
            trial_ends_at: this.$store.state.auth.user.trial_ends_at,
          })
        }

        const appLoading = document.getElementById('loading-bg')
        if (appLoading) {
          appLoading.style.display = 'none'
        }
      })
    } else {
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/vue/libs/vue-select.scss';
</style>
