<script>
import { BButton } from 'bootstrap-vue'

import { paymentPlans } from '@/core/config/payments'

export default {
  name: 'SelectPlanStep',
  components: {
    BButton,
  },
  props: ['data'],
  data() {
    return {
      paymentPlans: paymentPlans,
      scheduleCallLink: process.env.VUE_APP_SUBSCRIBE_FUNNEL_CALL_LINK,
    }
  },
}
</script>

<template>
  <div class="wrapper">
    <h2 class="info-title">
      {{ $t('Choose the plan that’s right for your business') }}
    </h2>
    <p class="info-content" v-html="$t('free_trial_info')"></p>
    <div class="plans-wrapper">
      <div
        class="plan-card"
        :class="{ 'active-plan': data.plan === 'plan_basic' }"
        @click="data.plan = 'plan_basic'"
      >
        <p class="plan-title">{{ $t('plan_basic') }}</p>
        <ul class="plan-features">
          <li>{{ $t('basic_plan_feature_1') }}</li>
          <li>{{ $t('basic_plan_feature_2') }}</li>
          <li>{{ $t('basic_plan_feature_3') }}</li>
        </ul>
        <span class="non-discounted-price"
          >{{ paymentPlans.plan_basic_yearly.non_discounted_price }} €
        </span>
        <p class="plan-price">
          {{ paymentPlans.plan_basic_yearly.price }} €
          <span class="plan-period"> {{ $t('plan_price') }} {{ $t('+IVA') }} </span>
        </p>
      </div>
      <div
        class="plan-card"
        :class="{ 'active-plan': data.plan === 'plan_pro' }"
        @click="data.plan = 'plan_pro'"
      >
        <p class="plan-title">{{ $t('plan_pro') }}</p>
        <ul class="plan-features">
          <li>{{ $t('pro_plan_feature_1') }}</li>
          <li>{{ $t('pro_plan_feature_2') }}</li>
          <li>{{ $t('pro_plan_feature_3') }}</li>
          <li>{{ $t('pro_plan_feature_4') }}</li>
          <li>{{ $t('pro_plan_feature_5') }}</li>
          <li>{{ $t('pro_plan_feature_6') }}</li>
          <li>{{ $t('pro_plan_feature_7') }}</li>
          <li>{{ $t('pro_plan_feature_8') }}</li>
        </ul>
        <span class="non-discounted-price"
          >{{ paymentPlans.plan_basic_yearly.non_discounted_price }} €
        </span>
        <p class="plan-price">
          {{ paymentPlans.plan_pro_yearly.price }} €
          <span class="plan-period"> {{ $t('plan_price') }} {{ $t('+IVA') }} </span>
        </p>
      </div>
    </div>
    <p>{{ $t('reduce_price_desc') }}</p>

    <div class="need-help">
      <div>
        <p class="need-help-title">{{ $t('Need help?') }}</p>
        <p class="need-help-content">
          {{
            $t(
              'Having trouble deciding which plan is right for you? Set up a demo call, and we can help.'
            )
          }}
        </p>
      </div>
      <a
        class="btn btn-outline-secondary need-help-button"
        :href="scheduleCallLink"
        target="_blank"
        rel="noopener noreferrer"
        >{{ $t('Schedule a call') }}</a
      >
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  margin: auto;
}

.info-title {
  margin: 0;
  font-size: 2rem;
}

.info-content {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  text-align: start;
}

.plans-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.plan-card.active-plan {
  border-color: #6bc4c5;
  background-color: #6bc4c5;
  color: #fff;
}

.plan-card {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: #c9c9c9;
  background-color: #fff;
  cursor: pointer;
  max-width: 420px;
}

.plan-title {
  font-size: 1.5rem;
}
.plan-features {
  flex: 1;
  margin-bottom: 24px;
}

.plan-price {
  text-align: center;
  font-size: 2rem;
}

.plan-period {
  font-size: 0.5em;
}

.non-discounted-price {
  text-align: center;
  text-decoration: line-through;
  font-size: 18px;
  margin-right: 48px;
  margin-bottom: 4px;
}

.need-help {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  margin-top: 24px;
  padding-bottom: 24px;
}

.need-help-title {
  font-size: 1.5rem;
}

.need-help-content {
  margin: 0;
}

.need-help-button {
  max-height: fit-content;
  align-self: flex-end;
  width: 100%;
  color: #4b64a5;
}

@media screen and (min-width: 768px) {
  .info-title {
    font-size: 2.5rem;
  }

  .plans-wrapper {
    flex-direction: row;
    gap: 48px;
  }

  .plan-card {
    min-width: 420px;
  }

  .need-help {
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
  }

  .need-help-button {
    max-width: fit-content;
  }
}
</style>
