import userCan from '@/libs/user-can'

// Here we should mark which items must be greyed out even if the still should be visitable
export function getNavItems(userData) {
  return [
    {
      title: 'Overview',
      route: 'overview',
      icon: 'home',
    },
    {
      title: 'Activities',
      route: 'activities',
      icon: 'activities',
    },
    {
      title: 'Camps',
      route: 'camps',
      icon: 'camps',
    },
    {
      title: '1-day Events',
      route: 'events',
      icon: 'events',
    },
    {
      title: 'Locations',
      route: 'locations',
      icon: 'addresses',
    },
    {
      title: 'Messages',
      route: 'messages',
      icon: 'envelope',
      paid: !userCan(userData.plan, 'messages'),
    },
    {
      title: 'Contacts',
      route: 'contacts',
      icon: 'contacts',
      paid: !userCan(userData.plan, 'contacts'),
    },
    {
      title: 'Bookings',
      route: 'bookings',
      icon: 'bookings',
      paid: !userCan(userData.plan, 'bookings'),
    },
    {
      title: 'Payments',
      route: 'payments',
      icon: 'wallet',
      // Probably paid property is used to style the item in a grey color
      paid: !userCan(userData.plan, 'payments'),
    },
    {
      title: 'Widget',
      route: 'widget',
      icon: 'box',
      paid: !userCan(userData.plan, 'widget'),
    },
  ]
}
