var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"info"},[_c('h2',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.$t('Put yourself on the map!')))]),_c('p',{staticClass:"info-content"},[_vm._v(_vm._s(_vm.$t('add_location_help_1')))]),_vm._m(0),_c('p',{staticClass:"info-content"},[_vm._v(_vm._s(_vm.$t('add_location_help_2')))])]),_c('div',{staticClass:"form"},[_c('b-form-group',{attrs:{"label":_vm.$t('Location name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Location name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"name","state":valid ? null : false,"placeholder":_vm.$t('Location name')},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_vm._l((errors),function(error){return _c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":"details"}},[_c('gmap-autocomplete',{ref:"locationautocomplete",staticClass:"form-control",attrs:{"value":_vm.data.details,"placeholder":_vm.$t('Search your address')},on:{"place_changed":_vm.handleAddressChange,"input":_vm.handleInput}})],1),_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":{ lat: _vm.data.latitude, lng: _vm.data.longitude },"zoom":15,"options":{
        fullscreenControl: false,
        zoomControl: true,
        rotateControl: false,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
      }}},[_c('GmapMarker',{attrs:{"position":{ lat: _vm.data.latitude, lng: _vm.data.longitude },"clickable":false}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screenshot-wrapper"},[_c('img',{staticClass:"screenshot",attrs:{"src":require("@/assets/images/screenshots/search-result.webp"),"alt":"Screenshot of search results in the map"}})])}]

export { render, staticRenderFns }