<template>
  <div class="vertical-layout h-100" :class="[layoutClasses]" :data-col="null">
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarTypeClass="[
          ...navbarTypeClass,
          'header-navbar navbar navbar-shadow align-items-center',
        ]"
      />
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="true"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div class="sidenav-overlay" :class="overlayClasses" @click="isVerticalMenuActive = false" />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition name="zoom-fade" mode="out-in">
      <component
        :is="layoutContentRenderer"
        :key="
          layoutContentRenderer === 'layout-content-renderer-left'
            ? $route.meta.navActiveLink || $route.name
            : null
        "
      >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import { BNavbar } from 'bootstrap-vue'

import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import LayoutContentRendererDefault from './components/LayoutContentRendererDefault.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  components: {
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
    } = useVerticalLayout('floating', 'hidden')

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
    }
  },
}
</script>

<style lang="scss">
@import '~@/styles/base/themes/bordered-layout.scss';
</style>
