export const paymentPlans = {
  plan_basic_yearly: {
    id: 'plan_basic_yearly',
    plan: 'plan_basic',
    period: 'yearly',
    name: 'Basic - Yearly',
    price: 99,
    non_discounted_price: 149,
  },
  plan_pro_yearly: {
    id: 'plan_pro_yearly',
    plan: 'plan_pro',
    period: 'yearly',
    name: 'Pro - Yearly',
    price: 299,
    non_discounted_price: 469,
  },
}
