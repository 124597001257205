<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'OnboardingFooter',
  props: ['steps', 'currentStep', 'isNextButtonDisabled'],
  components: {
    BButton,
  },
  methods: {
    goToStep(stepValue) {
      if (stepValue >= this.steps[this.currentStep].value) return
      else {
        const step = Object.values(this.steps).find(s => s.value === stepValue)
        this.$router.push({ query: { onboardingStep: step.key } })
      }
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <b-button
      v-if="steps[currentStep].prevStep"
      @click="$emit('prev', currentStep)"
      variant="outline-secondary"
      class="hide-on-mobile"
    >
      {{ $t(steps[currentStep].prevText) }}</b-button
    >
    <span v-else class="invisible-button hide-on-mobile"></span>
    <div v-show="steps[currentStep].value" class="timeline-wrapper">
      <span
        class="timeline-step"
        :class="{
          active: steps[currentStep].value === 1,
          completed: steps[currentStep].value > 1,
        }"
        @click="() => goToStep(1)"
        >{{ 1 }}</span
      >
      <span class="timeline-line"></span>
      <span
        class="timeline-step"
        :class="{
          active: steps[currentStep].value === 2,
          completed: steps[currentStep].value > 2,
        }"
        @click="() => goToStep(2)"
        >{{ 2 }}</span
      >
      <span class="timeline-line"></span>
      <span
        class="timeline-step"
        :class="{
          active: steps[currentStep].value === 3,
          completed: steps[currentStep].value > 3,
        }"
        @click="() => goToStep(3)"
        >{{ 3 }}</span
      >
    </div>
    <b-button
      v-if="steps[currentStep].prevStep"
      @click="$emit('prev', currentStep)"
      variant="outline-secondary"
      class="hide-on-desktop"
    >
      {{ $t(steps[currentStep].prevText) }}</b-button
    >
    <span v-else class="invisible-button hide-on-desktop"></span>

    <b-button
      v-if="steps[currentStep].nextStep"
      @click="$emit('next', currentStep)"
      :disabled="isNextButtonDisabled"
    >
      {{ $t(steps[currentStep].nextText) }}</b-button
    >
    <span v-else class="invisible-button"></span>
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  gap: 16px;
  margin: 0;
}
.hide-on-mobile {
  display: none;
}
.invisible-button {
  height: 100%;
}

.timeline-wrapper {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  grid-column: 1 / span 2;
}

.timeline-step {
  border-width: 1px;
  border-style: solid;
  border-color: #c9c9c9;
  border-radius: 50%;
  padding: 8px;
  height: 32px;
  width: 32px;
  font-size: 16px;
  line-height: 16px;
}

.active {
  background-color: #c9c9c9;
}

.completed {
  background-color: #6bc4c5;
  border-color: #6bc4c5;
  color: #fff;
  cursor: pointer;
}

.timeline-line {
  width: 100px;
  height: 1px;
  background-color: #c9c9c9;
}

@media screen and (min-width: 768px) {
  .wrapper {
    grid-template-columns: minmax(0, 160px) 1fr minmax(0, 160px);
  }

  .timeline-wrapper {
    grid-column: unset;
  }

  .hide-on-mobile {
    display: block;
  }
  .hide-on-desktop {
    display: none;
  }
}
</style>
