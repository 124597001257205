<script>
import { BButton } from 'bootstrap-vue'

import MyProfileService from '@/services/MyProfileService'
import StripeService from '@/services/StripeService'

export default {
  name: 'FinalSuccessStep',
  components: {
    BButton,
  },
  props: ['data'],
  data() {
    return {
      MyProfileService: new MyProfileService(),
      StripeService: new StripeService(),
    }
  },
  computed: {
    profileURL() {
      if (!this.data.slug) return ''
      return `${process.env.VUE_APP_FRONT_URL}/${this.$i18n.locale}/provider-details/${this.data.slug}`
    },
    searchURL() {
      const queryParams = new URLSearchParams({
        q: this.data.name,
        location: this.data.location.city || this.data.location.address,
        lat: this.data.location.latitude,
        lng: this.data.location.longitude,
        zoom: 15,
      })
      return `${process.env.VUE_APP_FRONT_URL}/search?${queryParams.toString()}`
    },
    screenshotURL() {
      if (!this.profileURL) return ''
      return `${process.env.VUE_APP_SCREENSHOT_SERVICE_URL}?url=${this.profileURL}`
    },
  },
  methods: {
    async goToDashboard() {
      await this.StripeService.startFreeTrial()
      this.$posthog.capture('Start free trial during onboarding')

      await this.$store.dispatch('auth/fetchUserData')

      this.$router.replace('/overview')
    },
  },
  mounted() {
    if (this.$route.query.isNewFreeTrial !== 'true') {
      this.MyProfileService.activatePlan({
        plan: this.$route.query.plan,
        payment_frequency: this.$route.query.period,
      }).then(res => {
        this.$posthog.capture('Subscribe for paid plan during onboarding', {
          plan: res.user.plan,
          period: res.user.payment_frequency,
          trial_ends_at: res.user.trial_ends_at,
          $set: {
            plan: res.user.plan,
            period: res.user.payment_frequency,
            trial_ends_at: res.user.trial_ends_at,
          },
        })
      })
    }
  },
}
</script>

<template>
  <div class="wrapper">
    <h2 class="info-title">{{ $t('Welcome to toddl.co!') }}</h2>
    <div class="info-wrapper">
      <div class="info-content-wrapper">
        <p
          v-if="$route.query.isNewFreeTrial === 'true'"
          class="info-description"
          v-html="$t('final_success_free_trial')"
        ></p>
        <p v-else class="info-description">
          {{
            $t(
              'Thank you for signing up to toddl.co, the largest booking platform for kids’ activities!'
            )
          }}
        </p>
        <div class="info-content">
          {{ $t('final_success_content_1') }}
          <a class="external-link" :href="profileURL" target="_blank"
            >{{ $t('open your profile') }} <feather-icon icon="ExternalLinkIcon" size="22" />
          </a>
          {{ $t('final_success_content_2') }}
          <a class="external-link" :href="searchURL" target="_blank"
            >{{ $t('search yourself') }} <feather-icon icon="ExternalLinkIcon" size="22"
          /></a>
        </div>

        <b-button @click="goToDashboard" size="lg">{{ $t('Get started') }}</b-button>
      </div>
      <div v-show="screenshotURL" class="screenshot-wrapper">
        <svg
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="
              0
              0
              632
              32
            "
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="border-radius: 8px 8px 0px 0px; background-color: rgb(54, 56, 58)"
        >
          <g fill="none" fill-rule="nonzero">
            <rect width="100%" fill="#36383a"></rect>
            <circle stroke="#F04744" fill="#FF5F56" cx="20" cy="17" r="6"></circle>
            <circle stroke="#EBA03F" fill="#FFBD2E" cx="40" cy="17" r="6"></circle>
            <circle stroke="#13AB42" fill="#27C93F" cx="60" cy="17" r="6"></circle>
          </g>
        </svg>
        <img :src="screenshotURL" alt="Screenshot of provider details page" class="screenshot" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
  margin: auto;
}

.info-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 16px;
  height: 100%;
}

.info-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.info-title {
  margin: 0;
  font-size: 2.25rem;
}

.info-description {
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0;
}

.info-content {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 24px;
}

.screenshot-wrapper {
  max-width: 100%;
  align-self: center;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
}

.screenshot {
  width: 100%;
  aspect-ratio: 16 / 10;
  border-radius: 0px 0px 8px 8px;
}

.external-link {
  color: #4b64a5;
}

@media screen and (min-width: 768px) {
  /* .wrapper {
    max-width: 75%;
  } */

  .info-wrapper {
    flex-direction: row;
  }

  .info-title {
    font-size: 3rem;
  }

  .info-description {
    font-size: 1.5rem;
  }

  .info-content {
    font-size: 1.5rem;
  }

  .screenshot-wrapper {
    max-width: 50%;
  }
}
</style>
