import CrudService from '@/services/shared/http/CrudService'

export default class StripeService extends CrudService {
  constructor(params = {}) {
    super('stripe', params)
  }

  getStripeData() {
    return this.api.GET(this.endPoint + '/manage')
  }

  subscribe(params) {
    return this.api.POST(this.endPoint + '/subscribe', params)
  }

  upgrade(params) {
    return this.api.POST(this.endPoint + '/promote', params)
  }

  updateBillingDetails(params) {
    return this.api.PUT(this.endPoint + '/billing', params)
  }

  getCustomer() {
    return this.api.GET(this.endPoint + '/customer')
  }

  startFreeTrial() {
    return this.api.POST(this.endPoint + '/free-trial')
  }
}
