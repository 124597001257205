<template>
  <div class="lang-wrapper">
    <feather-icon icon="GlobeIcon" size="20" class="globe float-left" />
    <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
      <template #button-content>
        <span class="ml-50 mr-3 text-body text-uppercase loc">{{ currentLocale.locale }}</span>
      </template>
      <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="
          $i18n.locale = localeObj.locale
          saveLocaleInStorage(localeObj.locale)
        "
      >
        <span class="loc ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <feather-icon icon="ChevronDownIcon" size="20" class="text-primary lang-chevron" />
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import store from '@/store'
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    const locales = [
      {
        locale: 'en',
        name: 'English',
      },
      {
        locale: 'es',
        name: 'Spanish',
      },
    ]
    const saveLocaleInStorage = lang => {
      localStorage.setItem('locale', lang)
      store.commit('appConfig/SET_LANG', lang)
    }
    return {
      locales,
      saveLocaleInStorage,
    }
  },
}
</script>

<style>
.globe {
  color: #bbb;
  display: inline;
  margin-left: 1em;
}
.lang-chevron {
  position: absolute;
  right: 1em;
  top: 1.3em;
}

@media (max-width: 600px) {
  .globe {
    margin-right: -0.5em;
  }
  .lang-chevron {
    display: none;
  }

  .loc {
    margin-right: 0 !important;
  }
}
</style>
