<template>
  <div>
    <div class="my-profile-wrapper">
      <router-link v-if="displayUpgradeButton" to="/subscribe" class="subscribe-button">
        {{ $t('upgrade_plan_cta') }}
      </router-link>
      <router-link
        v-if="displayAddPaymentMethodButton"
        to="/manage-subscription"
        class="subscribe-button"
      >
        {{ $t('add_payment_method_cta') }}
      </router-link>
      <router-link to="/my-profile" class="modify-profile">
        <feather-icon size="16" icon="UserIcon" class="mr-50 white" />
        {{ $t('modify_profile') }}
      </router-link>
    </div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div>
          <b-avatar
            v-if="$store.state.auth.user && $store.state.auth.user.profile_image"
            size="40"
            :src="$store.state.auth.user.profile_image"
            variant="light-primary"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            variant="primary"
            class="badge-minimal"
            badge-variant="success"
            :text="placeholderName"
          />
        </div>
      </template>

      <b-dropdown-item
        v-if="displayManageSubscriptionLink"
        :to="{ name: 'manage-subscription' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="BookmarkIcon" class="mr-50 text-primary" />
        <span>{{ $t('Subscription') }}</span>
      </b-dropdown-item>
      <b-dropdown-item :to="{ name: 'my-profile' }" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="UserIcon" class="mr-50 text-primary" />
        <span>{{ $t('My Profile') }}</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50 text-primary" />
        <span>{{ $t('Sign Out') }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'

import AuthService from '@/services/AuthService'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      authService: new AuthService(),
    }
  },
  computed: {
    isUserOnTrial() {
      if (this.$store.state.auth.user && this.$store.state.auth.user.trial_ends_at) {
        const diffInMs = new Date(this.$store.state.auth.user.trial_ends_at) - new Date()

        return diffInMs > 0
      }
      return false
    },
    displayUpgradeButton() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.plan !== 'plan_pro' &&
        !this.isUserOnTrial
      )
    },
    displayAddPaymentMethodButton() {
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.plan === 'plan_basic' &&
        this.isUserOnTrial
      )
    },
    displayManageSubscriptionLink() {
      return this.$store.state.auth.user && this.$store.state.auth.user.plan !== 'restricted'
    },
    placeholderName() {
      if (!this.$store.state.auth.user) return
      const chars = this.$store.state.auth.user.name.split(' ')
      if (chars.length > 1) {
        return `${chars[0][0]}${chars[1][0]}`
      } else {
        return `${chars[0][0]}${chars[0][1]}`
      }
    },
  },
  methods: {
    logout() {
      this.authService.logout().then(
        () => {
          this.$store.commit('auth/logout')

          localStorage.removeItem('isImpersonatingUser')

          this.$posthog.reset()

          this.$router.push({ name: 'auth-login' })
        },
        error => {
          console.log(error)
        }
      )
    },
  },
}
</script>
<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 15rem;
}

.modify-profile {
  background-color: #6bc4c5;
  padding: 0.6em;
  border-radius: 4px;
  font-weight: 600;
  color: #fff !important;
  margin-right: 1em;
}
.subscribe-button {
  background-color: #4b64a5;
  padding: 0.6em;
  border-radius: 4px;
  font-weight: 600;
  color: #fff !important;
  margin-right: 1em;
}

.my-profile-wrapper {
  display: inline;
  position: absolute;
  right: 145px;
  top: 1.3em;
  cursor: pointer;
}

@media (max-width: 600px) {
  .my-profile-wrapper {
    display: inline;
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    max-width: 200px;
    margin: 1.3em 3em 0;
    text-align: center;
  }

  .modify-profile {
    display: none;
  }

  .subscribe-button {
    margin-left: 0.75em;
    margin-right: 0.25em;
    font-size: 0.8em;
    padding: 0.6em 0.6em;
  }
}

.white {
  color: #fff;
}
</style>
