<script>
export default {
  name: 'FreeTrialReminder',
  props: ['daysLeft'],
}
</script>

<template>
  <div
    class="free-trial-reminder hide-in-mobile"
    :class="{ alert: daysLeft < 7, danger: daysLeft < 3 }"
  >
    {{ $t('Trial X days left', { daysLeft }) }}
  </div>
</template>

<style scoped>
.hide-in-mobile {
  display: none;
}
.free-trial-reminder {
  padding: 4px 8px;
  background-color: rgba(107, 196, 197, 1);
  border: 1px solid rgba(107, 196, 197, 1);
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0;
}

.alert {
  background-color: rgba(242, 225, 120, 1);
  border-color: rgba(242, 225, 120, 1);
  color: #555;
}

.danger {
  background-color: rgba(253, 131, 137, 1);
  border-color: rgba(253, 131, 137, 1);
  color: #fff;
}

@media screen and (min-width: 768px) {
  .hide-in-mobile {
    display: block;
  }
}
</style>
