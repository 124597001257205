<script>
import { BModal } from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js/pure'
import { ValidationObserver } from 'vee-validate'

import { paymentPlans } from '@/core/config/payments'
import LocationService from '@/services/LocationService'
import MyProfileService from '@/services/MyProfileService'
import StripeService from '@/services/StripeService'

import Footer from './Footer'
import CompleteProfileStep from './CompleteProfileStep'
import AddLocationStep from './AddLocationStep'
import MiddleSuccessStep from './MiddleSuccessStep'
import SelectPlanStep from './SelectPlanStep'
import BankingDetailsStep from './BankingDetailsStep'
import FinalSuccessStep from './FinalSuccessStep'

const VALID_STEPS = [
  'complete-profile',
  'add-location',
  // 'success-middle',
  // 'select-plan',
  // 'banking-details',
  'success-final',
]

export default {
  name: 'Onboarding',
  props: ['showOnboarding'],
  components: {
    BModal,
    ValidationObserver,
    Footer,
    CompleteProfileStep,
  },
  data() {
    return {
      MyProfileService: new MyProfileService(),
      LocationService: new LocationService(),
      StripeService: new StripeService(),
      isNextButtonDisabled: false,
      paymentPlans: paymentPlans,
      currentStep: 'complete-profile',
      steps: {
        'complete-profile': {
          key: 'complete-profile',
          title: 'Set up your profile!',
          value: 1,
          prevText: '',
          prevStep: '',
          nextText: 'Save',
          nextStep: 'add-location',
          content: CompleteProfileStep,
          data: {
            logo: '',
            business_name: '',
            name: '',
            email: '',
            phone: '',
            website_url: '',
            facebook_url: '',
            instagram_url: '',
            ageRange: [0, 19],
            categories: [],
            about: '',
            files: [],
            mediaPreviews: [],
          },
        },
        'add-location': {
          key: 'add-location',
          title: 'Put yourself on the map!',
          value: 2,
          prevText: 'Back',
          prevStep: 'complete-profile',
          nextText: 'Save',
          nextStep: 'success-final',
          content: AddLocationStep,
          data: {
            name: '',
            details: '',
            city: '',
            google_place_id: '',
            metadata: '',
            longitude: 2.168568,
            latitude: 41.3873974,
          },
        },
        'success-middle': {
          key: 'success-middle',
          title: 'Congratulations!',
          value: 2.5,
          prevText: 'Back',
          prevStep: 'add-location',
          nextText: 'Next',
          nextStep: 'select-plan',
          content: MiddleSuccessStep,
          data: {
            slug: '',
          },
        },
        'select-plan': {
          key: 'select-plan',
          title: 'Almost ready!!!',
          value: 3,
          prevText: 'Back',
          prevStep: 'add-location',
          nextText: 'Select',
          nextStep: 'banking-details',
          content: SelectPlanStep,
          data: {
            plan: 'plan_basic',
            period: 'yearly',
          },
        },
        'banking-details': {
          key: 'banking-details',
          title: 'Start your free trial',
          value: 4,
          prevText: 'Back',
          prevStep: 'select-plan',
          nextText: 'Start free trial',
          nextStep: 'success-final',
          content: BankingDetailsStep,
          stripeClient: undefined,
          data: {
            stripeElements: undefined,
            stripeError: '',
            amount: 99,
          },
        },
        'success-final': {
          key: 'success-final',
          title: 'Welcome to toddl.co',
          value: 0,
          prevText: '',
          prevStep: '',
          nextText: '',
          nextStep: '',
          content: FinalSuccessStep,
          data: {
            slug: '',
            name: '',
            location: JSON.parse(this.$route.query.location ?? '{}'),
          },
        },
      },
    }
  },
  computed: {
    missingFields() {
      if (this.currentStep === 'add-location') {
        return !this.steps['add-location'].data.details
      }
      return false
    },
  },
  methods: {
    async handleOnNext(step) {
      switch (step) {
        case 'complete-profile':
          this.isNextButtonDisabled = true
          try {
            const isValid = await this.$refs.onboardingForm.validate()
            if (isValid) {
              const payload = new FormData()
              payload.append('_method', 'put')
              const IGNORED_KEYS = ['mediaPreviews']
              Object.entries(this.steps[step].data).forEach(([key, value]) => {
                // All this  mapping is required to avoid sending empty values, send proper array of values
                // and have improved API in the form. Reconsider it when converting endpoint to accept json body
                if (!value || IGNORED_KEYS.includes(key)) return
                if (key === 'ageRange') {
                  payload.append('min_age', value[0])
                  payload.append('max_age', value[1])
                  return
                }
                if (Array.isArray(value)) {
                  value.forEach(v => {
                    payload.append(`${key}[]`, v)
                  })
                  return
                }
                if (key === 'logo') {
                  if (typeof value === 'string') return
                  payload.append('profile_image', value)
                  return
                }

                payload.append(key, value)
              })

              const { activity, location, types, ...user } =
                await this.MyProfileService.updateProfileDetails(payload)

              this.$store.commit('auth/setUser', user)

              this.$posthog.capture('Complete profile during onboarding', {
                $set: {
                  name: payload.get('name'),
                  provider: payload.get('business_name'),
                },
                hasUpdatedBusinessName:
                  payload.get('business_name') !== this.$store.state.auth.user.business_name,
                hasUpdatedName: payload.get('name') !== this.$store.state.auth.user.name,
                hasUpdatedPhone: payload.get('phone') !== this.$store.state.auth.user.phone,
                isNewUser:
                  new Date(this.$store.state.auth.user.created_at) >
                  new Date('2024-04-16T00:00:00.000000Z'),
              })

              this.steps['add-location'].data.name = payload.get('business_name')

              const profileImage = new Image()
              profileImage.src = `${process.env.VUE_APP_SCREENSHOT_SERVICE_URL}?url=${process.env.VUE_APP_FRONT_URL}/${this.$i18n.locale}/provider-details/${this.$store.state.auth.user.slug}`

              this.isNextButtonDisabled = false
              this.$router.push({ query: { onboardingStep: this.steps[step].nextStep } })
            } else {
              this.isNextButtonDisabled = false
              // Open a notification with the error?
              console.error('Form is not valid')
            }
          } catch (error) {
            this.isNextButtonDisabled = false
            // Open a notification with the error?
            console.error(error)
          }
          break
        case 'add-location':
          this.isNextButtonDisabled = true
          try {
            const isValid = await this.$refs.onboardingForm.validate()
            if (isValid) {
              if (this.$store.state.auth.user.main_location_id) {
                await this.LocationService.update(
                  this.$store.state.auth.user.main_location_id,
                  this.steps[step].data
                )
              } else {
                const newLocation = await this.LocationService.create(this.steps[step].data)

                const profilePayload = new FormData()
                profilePayload.append('_method', 'put')
                profilePayload.append('name', newLocation.user.name)
                profilePayload.append('business_name', newLocation.user.business_name)
                profilePayload.append('phone', newLocation.user.phone)
                profilePayload.append('main_location_id', newLocation.id)

                const { activity, location, types, ...user } =
                  await this.MyProfileService.updateProfileDetails(profilePayload)
                this.$store.commit('auth/setUser', user)
              }
              this.$posthog.capture('Add location during onboarding')
              this.steps['success-final'].data.location = JSON.stringify({
                address: this.steps[step].data.details,
                city: this.steps[step].data.city,
                latitude: this.steps[step].data.latitude,
                longitude: this.steps[step].data.longitude,
              })
            } else {
              this.isNextButtonDisabled = false
              console.error('Form is not valid')
            }

            this.isNextButtonDisabled = false
            this.$router.push({
              query: { onboardingStep: this.steps[step].nextStep, isNewFreeTrial: true },
            })
          } catch (error) {
            this.isNextButtonDisabled = false
            // Open a notification with the error?
            console.error(error)
          }

          break
        case 'success-middle':
          this.$posthog.capture('Saw middle success during onboarding')
          this.$router.push({ query: { onboardingStep: this.steps[step].nextStep } })
          break
        case 'select-plan':
          this.isNextButtonDisabled = true
          try {
            const res = await this.StripeService.subscribe({
              plan: this.steps[step].data.plan,
              period: this.steps[step].data.period,
            })

            this.$posthog.capture('Select plan during onboarding', {
              plan: this.steps[step].data.plan,
              period: this.steps[step].data.period,
            })

            if (!res.subscription.pending_setup_intent.client_secret)
              throw new Error('No client secret')

            this.steps['banking-details'].data.amount =
              this.paymentPlans[
                `${this.steps[step].data.plan}_${this.steps[step].data.period}`
              ].price
            const stripeClient = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
            this.steps['banking-details'].stripeClient = stripeClient

            this.steps['banking-details'].data.stripeElements = stripeClient.elements({
              clientSecret: res.subscription.pending_setup_intent.client_secret,
              fonts: [
                {
                  cssSrc:
                    'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap',
                },
              ],
              locale: this.$i18n.locale,
              appearance: {
                theme: 'stripe',
                variables: {
                  fontFamily: 'Poppins, sans-serif',
                  fontSizeBase: '14px',
                  colorPrimary: '#435993',
                  colorBackground: '#fff',
                  colorText: '#0E1A1A',
                  colorDanger: '#FD8389',
                  borderRadius: '4px',
                },
              },
            })

            this.isNextButtonDisabled = false
            this.$router.push({ query: { onboardingStep: this.steps[step].nextStep } })
          } catch (error) {
            this.isNextButtonDisabled = false
            // Open a notification with the error?
            console.error(error)
          }

          break
        case 'banking-details':
          const queryParam = new URLSearchParams({
            onboardingStep: 'success-final',
            plan: this.steps['select-plan'].data.plan,
            period: this.steps['select-plan'].data.period,
            location: this.steps['success-final'].data.location,
          })
          const return_url = `${process.env.VUE_APP_BASE_URL}/overview?${queryParam.toString()}`
          this.isNextButtonDisabled = true
          try {
            const res = await this.steps[step].stripeClient.confirmSetup({
              elements: this.steps[step].data.stripeElements,
              confirmParams: {
                return_url,
              },
            })

            if (res.error) {
              // Show notification with error
              this.steps[step].data.stripeError = res.error.message
              console.error('error', res.error.message)
              this.isNextButtonDisabled = false
            } else {
              this.isNextButtonDisabled = false
              this.$router.push({ query: { onboardingStep: this.steps[step].nextStep } })
            }
          } catch (error) {
            console.error(error)
            this.steps[step].data.stripeError = 'Something went wrong. Please try again.'
            this.isNextButtonDisabled = false
          }

          break
        default:
          break
      }
    },
    handleOnPrev(step) {
      switch (step) {
        case 'add-location':
        case 'success-middle':
        case 'select-plan':
        case 'banking-details':
          this.$router.push({ query: { onboardingStep: this.steps[step].prevStep } })
          break
        default:
          break
      }
    },
    async prePopulateStepsData() {
      const user = this.$store.state.auth.user

      this.steps['complete-profile'].data.logo = user.profile_image
      this.steps['complete-profile'].data.business_name = user.business_name
      this.steps['complete-profile'].data.name = user.name
      this.steps['complete-profile'].data.about = user.about
      this.steps['complete-profile'].data.files = user.media.map(m => m.id)
      this.steps['complete-profile'].data.mediaPreviews = user.media.map(m => ({
        id: m.id,
        original_url: m.original_url,
      }))
      this.steps['complete-profile'].data.email = user.email
      this.steps['complete-profile'].data.phone = user.phone
      this.steps['complete-profile'].data.website_url = user.website_url
      this.steps['complete-profile'].data.facebook_url = user.facebook_url
      this.steps['complete-profile'].data.instagram_url = user.instagram_url
      this.steps['complete-profile'].data.categories = user.categories.map(c => c.id)
      this.steps['complete-profile'].data.ageRange = [user.min_age, user.max_age]

      this.steps['add-location'].data.name = user.business_name
      if (user.main_location) {
        this.steps['add-location'].data.details = user.main_location.details
        this.steps['add-location'].data.city = user.main_location.city?.name
        this.steps['add-location'].data.google_place_id = user.main_location.google_place_id
        this.steps['add-location'].data.metadata = user.main_location.metadata
        this.steps['add-location'].data.latitude = parseFloat(user.main_location.latitude)
        this.steps['add-location'].data.longitude = parseFloat(user.main_location.longitude)
      }

      this.steps['success-middle'].data.slug = user.slug

      this.steps['success-final'].data.slug = user.slug
      this.steps['success-final'].data.name = user.business_name
    },
  },
  watch: {
    '$route.query.onboardingStep': function (newValue) {
      if (VALID_STEPS.includes(newValue)) this.currentStep = newValue
      else this.currentStep = 'complete-profile'
    },
  },
  mounted() {
    this.prePopulateStepsData()

    if (
      this.$route.query?.onboardingStep === 'banking-details' &&
      !this.steps['banking-details'].data.stripeElements
    ) {
      this.$router.push({ query: { onboardingStep: 'select-plan' } })
    }

    if (VALID_STEPS.includes(this.$route.query?.onboardingStep))
      this.currentStep = this.$route.query?.onboardingStep
    else this.currentStep = 'complete-profile'
  },
}
</script>

<template>
  <validation-observer
    ref="onboardingForm"
    tag="form"
    v-slot="{ invalid }"
    style="max-height: 100%"
  >
    <BModal
      id="onboarding"
      v-model="showOnboarding"
      :centered="true"
      size="xl"
      content-class="onboarding-modal"
      :aria-label="$t(steps[currentStep].title)"
      scrollable
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <component :is="steps[currentStep].content" :data="steps[currentStep].data" />
      <template #modal-footer>
        <Footer
          :steps="steps"
          :currentStep="currentStep"
          @next="handleOnNext"
          @prev="handleOnPrev"
          :isNextButtonDisabled="invalid || isNextButtonDisabled || missingFields"
        />
      </template>
    </BModal>
  </validation-observer>
</template>

<style>
.modal-body {
  padding: 2rem;
}

.onboarding-modal {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
}

@media screen and (min-width: 768px) {
  .onboarding-modal {
    width: auto;
    height: 80vh;
    border-radius: 8px;
  }
}
</style>
