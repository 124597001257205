<script>
export default {
  name: 'BankingDetailsStep',
  props: ['data'],
  computed: {
    trialEndDate() {
      const FOURTEEN_DAYS = 14 * 24 * 60 * 60 * 1000
      const endDate = new Date(Date.now() + FOURTEEN_DAYS)
      return endDate.toLocaleDateString(this.$i18n.locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
    },
  },
  mounted() {
    const paymentElement = this.data.stripeElements.create('payment', {
      wallets: { applePay: 'never', googlePay: 'never' },
    })
    paymentElement.mount('#payment-element')
  },
}
</script>

<template>
  <div class="wrapper">
    <h2 class="info-title">{{ $t('Start your free trial') }}</h2>
    <p class="info-content" v-html="$t('free_trial_info')"></p>
    <div class="main-content">
      <div class="stripe-form">
        <div id="payment-element"></div>
        <p v-if="data.stripeError" class="stripe-error" role="alert">
          {{ data.stripeError }}
        </p>
      </div>
      <div class="info">
        <div class="summary">
          <p class="summary-title">{{ $t('Summary') }}</p>
          <p class="summary-content">
            {{ $t('summary_content', { amount: data.amount, trialEndDate }) }}
          </p>
          <div class="summary-duenow">{{ $t('due_now', { dueNow: 0 }) }}</div>
        </div>
        <div class="questions">
          <p>{{ $t('banking_details_question_1') }}</p>
          <p>{{ $t('banking_details_answer_1A', { trialEndDate }) }}</p>
          <p>{{ $t('banking_details_answer_1B', { amount: data.amount }) }}</p>
          <p style="margin-top: 12px">{{ $t('banking_details_question_2') }}</p>
          <p>{{ $t('banking_details_answer_2', { trialEndDate }) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  margin: auto;
}

.info-title {
  margin: 0;
}

.info-content {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  text-align: start;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.stripe-form {
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.summary-title {
  font-size: 1.5rem;
}

.summary-content {
  margin-bottom: 32px;
}

.summary-duenow {
  font-size: 1.25rem;
  text-align: end;
}

.questions p {
  margin: 0;
  font-size: 12px;
}

.stripe-error {
  color: #fd8389;
  font-size: 1rem;
}

@media screen and (min-width: 768px) {
  .main-content {
    flex-direction: row;
    gap: 32px;
  }

  .stripe-form {
    width: calc(50% - 16px);
  }

  .info {
    width: calc(50% - 16px);
  }

  .summary {
    margin-bottom: 64px;
  }
}
</style>
