<script>
export default {
  name: 'MiddleSuccessStep',
  props: ['data'],
  computed: {
    screenshotURL() {
      if (!this.data.slug) return ''
      return `${process.env.VUE_APP_SCREENSHOT_SERVICE_URL}?url=${process.env.VUE_APP_FRONT_URL}/${this.$i18n.locale}/provider-details/${this.data.slug}`
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <h2 class="info-title">{{ $t('Congratulations!') }}</h2>
    <div v-show="screenshotURL" class="screenshot-wrapper">
      <svg
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="
            0
            0
            632
            32
          "
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="border-radius: 8px 8px 0px 0px; background-color: rgb(54, 56, 58)"
      >
        <g fill="none" fill-rule="nonzero">
          <rect width="100%" fill="#36383a"></rect>
          <circle stroke="#F04744" fill="#FF5F56" cx="20" cy="17" r="6"></circle>
          <circle stroke="#EBA03F" fill="#FFBD2E" cx="40" cy="17" r="6"></circle>
          <circle stroke="#13AB42" fill="#27C93F" cx="60" cy="17" r="6"></circle>
        </g>
      </svg>
      <img :src="screenshotURL" alt="Screenshot of provider details page" class="screenshot" />
    </div>
    <p class="info-content">{{ $t('middle_success_help_1') }}</p>
    <p class="info-content">{{ $t('middle_success_help_2') }}</p>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
  margin: auto;
}

.info-title {
  margin: 0;
  font-size: 2.25rem;
}

.info-content {
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0;
}

.screenshot-wrapper {
  max-width: 100%;
  align-self: center;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
}

.screenshot {
  width: 100%;
  aspect-ratio: 16 / 10;
  border-radius: 0px 0px 8px 8px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    max-width: 75%;
  }

  .info-title {
    font-size: 3rem;
  }

  .info-content {
    font-size: 1.5rem;
    text-align: center;
  }

  .screenshot-wrapper {
    max-width: 50%;
  }
}
</style>
